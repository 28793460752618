var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  })},[_c('div',{style:({
      width: '400px',
      maxWidth: '90vw'
    })},[_c('h3',{staticClass:"mb-5"},[_vm._v("Hai dimenticato la password?")]),(!_vm.sended)?_c('v-text-field',{staticClass:"mt-4",attrs:{"solo":"","label":"Email","clearable":"","append-icon":"mdi-email","loading":_vm.loading},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}):_c('div',{staticClass:"mt-4 mb-4 text-center"},[_vm._v(" abbiamo inviato a "),_c('b',[_vm._v(_vm._s(_vm.email))]),_vm._v(" le istruzioni per il reset. ")]),(!_vm.sended)?_c('v-btn',{attrs:{"color":"primary","block":"","loading":_vm.loading,"disabled":!_vm.email},on:{"click":_vm.handleSendClick}},[_vm._v("Invia email")]):_c('v-btn',{attrs:{"color":"primary","block":""},on:{"click":_vm.handleBackToLoginClick}},[_vm._v("Torna al login")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }